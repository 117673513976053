<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            :disabled="selectedRow.length < 1"
            @click="onConfirmAction"
          >
            Revoke Voucher
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AdminVoucherService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminVouchers',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Vouchers'
    }
  },

  mixins: [formatter],

  data () {
    return {
      editMode: false,
      isBusy: false,
      selectedRow: [],
      hasSelectedRow: false,
      organization: {
        id: '',
        active: 0,
        organization_code: '',
        organization_name: ''
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'selected',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'voucher_code' },
          { key: 'voucher_unifi_id', label: 'voucher id' }
        ]
      },
      voucher_id: []
    }
  },

  computed: {

  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminVoucherService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        this.voucher_id = data.items
        return data.items
      } catch {
        return []
      }
    },

    onRowSelected (row) {
      this.selectedRow = row
      this.hasSelectedRow = row.length > 0
    },

    onConfirmAction () {
      if (this.selectedRow.length < 1) {
        return
      }
      const title = 'Do you really want to revoke voucher?'
      this.$swal.fire({
        icon: 'question',
        title: title,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.onRevokeVoucher()
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onRevokeVoucher () {
      return new Promise(resolve => {
        const all = []

        this.selectedRow.forEach(row => {
          all.push(row.voucher_unifi_id)
        })

        if (all.length < 1) {
          return
        }

        AdminVoucherService.post({ selected_ids: all }).then(({ data }) => {
          this.isBusy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(() => {
          this.isBusy = false
        })
      })
    }
  }
}
</script>
